//posicionamento do menu fixo no topo
.main-header-bundle {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: z(menu);
}

.main-header {
    color: get-color(dark);
    
    h1 {
        margin: 0;
    }
}

//menu desktop


.is-active {
    .main-header__logo {
        height: 33px;
    }
    .desktop-menu{
        background-color: #000;
        box-shadow: 0 0.2rem 0.7rem rgba(#000, 0.1);
        .container{
            padding-top: 10px;
            padding-bottom: 10px;
        }
        .access-content{
            padding-top: 15px;
        }
        .access-item{
            display: flex;
            flex-direction: row;
            font-size: 12px;
            text-align: left;
        }

    }

    .mobile-menu {
        background-color: #000;
        box-shadow: 0 0.2rem 0.7rem rgba(#000, 0.1);
    }

    .detail-navigation-bar{
        position: fixed;
        background: #000;
        top: -1px;
        .container{
            padding: 0;
            margin: 0;
            max-width: unset;
            // padding: 20px;
            padding: 10px;
            padding-left: 30px;
            padding-right: 30px;
            max-width: 1200px;
            margin:  0 auto;
        }
    }
}


.desktop-menu {
    display: none;
    transition: 0.4s;
    @media (min-width: 1200px) {
        display: block;
    }
    &.active{
        background-color: #000;
        box-shadow: 0 0.2rem 0.7rem rgba(#000, 0.1);
        .container{
            padding-top: 10px;
            padding-bottom: 10px;
        }
        .access-content{
            padding-top: 15px;
        }
        .access-item{
            display: flex;
            flex-direction: row;
            font-size: 12px;
            text-align: left;
        }

    }
    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 0;
    }

    .main-header__logo-container {
        @media (min-width: 1200px) {
            width: 200px;
            height: unset;
            margin-left: -40px !important;
        }
    }
    
    .main-header__logo {
        width: 100%;
        height: 100%;
        display: inline-block;
        object-fit: contain;
    }

    .main-header__content {
        transition: 0.2s;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 40px;
    }

    .access-content{
        display: flex;
        align-items: center;
        .access-item{
            align-items: center;
            text-transform: uppercase;
            width: 172px;
            color: #fff;
            font-size: 14px;
            margin-left: 20px;
            line-height: 1.2;
            background-color: rgba(black, 0.4);
            padding: 10px;
            padding-top: 35px;
            border-radius: 20px;
            margin-top: -40px;
            transition: 0.4s;
            text-align: center;
            font-size: 10px;
            &:hover{
                opacity: 0.8;
            }
            .icon{
                width: 35px;
                height: 35px;
                margin: auto;
                margin-bottom: 5px;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            .text{
                flex: 1;
                font-weight: 600;
                font-size: 12px;
            }
           
        }
    }
}

//menu mobile
.mobile-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 5px 20px;
    transition: 0.4s;

    @media (min-width: 1200px) {
        display: none;
    }

    .chat-area {
        color: #fff;
        font-size: 22px;
        position: relative;
        width: 40px;
    }

    &.active{
        background-color: #000;
        box-shadow: 0 0.2rem 0.7rem rgba(#000, 0.1);
    }

    .mobile-logo {
        width: 130px;
        margin: 0;
        margin-top: -5px;
    }

    //controle da cor do burger menu (acesso facil)
    .burger-icon {
        background-color: #fff;
        &::before {
            background-color: #fff;
        }

        &::after {
            background-color: #fff;
        }
    }
}

//espacamento do conteudo pra barra do topo
.main-header-clearance {
    padding-top: var(--main-header-height);
}

.is-active {
    .main-header__logo-container {
        @media (min-width: 1200px) {
            height: 45px;
        }
    }
    .detail-navigation-bar {
        display: block !important;
        
       

        .container {
            @media (max-width: 1200px) {
                display: grid;
                grid-template-columns: auto auto;
                justify-content: center;
                grid-column-gap: 20px;
            }
        }

        a {
            @media (max-width: 1200px) {
                padding: 10px;
                text-align: center;
            }
        }
    }
}

.contact-top-area {
    width: 100%;
    background: #000;
    padding: 20px;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
    transform: translateY(-20px);
    position: fixed;
    top: 55px;
    z-index: 99;

    &.is-active {
        visibility: visible;
        opacity: 1;
        transform: translateY(0px);
    }

    .container-contact {
        display: flex;
    }
    .title {
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #ffffff2b;
        padding-right: 17px;
        margin-right: 17px;
        i {
            margin-bottom: 10px;
        }
        p{
            writing-mode: vertical-lr;
        }
    }
    .contacts {
        width: 100%;
    }
    .contact-line {
        color: #fff;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        border-bottom: 1px solid #ffffff38;
        padding-bottom: 10px;
        padding-top: 20px;

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            border: none;
            padding-bottom: 0;
        }
        
        b{
            display: block;
            text-align: left;
        }
        i {
            font-size: 25px;
            margin-right: 10px;
        }

        p {
            margin-left: 10px;
        }
    }

}

.is-hidden {
    display: none;
}