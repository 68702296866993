#video-galeria {
    padding-bottom: 60px;
    .big-text {
        text-align: center;
        margin-bottom: 50px;
    }
}

.grid-videos {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    @include media(get-bp(desktop)) {
        flex-direction: row;
    }

    .video {
        position: relative;
        width:  50%;
        width:  calc(100% / 2  - 10px);
        margin-right: 10px;
        margin-bottom: 10px;

        height: 200px;
        @include media(get-bp(desktop)) {
            width:  calc(100% / 4  - 5px);
            margin-right: 5px;
            // height: 200px;
        }

        iframe, video {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
            pointer-events: none;
        }
    }
}