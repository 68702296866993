.certifications{
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
    @media (min-width: 1200px){
        padding-left: 185px;
        text-align: left;
    }
    .title-row{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        justify-content: center;
        @media (min-width: 1200px){
            justify-content: flex-start;
        }
        .icon{
            margin-right: 15px;
        }
        .text{
            font-weight: bold;
            font-size: 22px;
            text-transform: uppercase;
        }
    }
    .certi-listing{
        font-weight: 100;
        // text-transform: uppercase;
        @media (min-width: 1200px){
            text-transform: none;
        }
    }
}