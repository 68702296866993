.we-are{
    margin-top: 50px;
    margin-bottom: 50px;
    @media (min-width: 1200px){
        margin-top: 150px;
        margin-bottom: 100px;
    }
    .container{
        @media (min-width: 1200px){
            display: flex;
            align-items: center;
        }
        .left-side{
            margin-bottom: 50px;
            @media (min-width: 1200px){
                margin-bottom: 0;
                width: 50%;
                padding-left: 10%;
                margin-right: 70px;
            }

            .title{
                font-weight: bold;
                text-transform: uppercase;
                font-size: 42px;
                line-height: 1;
                margin-bottom: 15px;
                @media (min-width: 1200px){
                    font-size: 72px;
                }
            }

            .subtitle{
                font-size: 16px;
                @media (min-width: 1200px){
                    font-size: 18px;
                }
            }
        }
        .right-side{
            @media (min-width: 1200px){
                width: 50%;
            }

            .desktop-badges{
                display: none;
                flex-wrap: wrap;
                @media (min-width: 1200px){
                    display: flex;
                }
                
            }

            .mobile-badges{
                @media (min-width: 1200px){
                    display: none;
                }
            }
        }
        .badges-slider{
            padding-left: 30px;
        }
        .swiper-slide{
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .swiper-pagination{
            margin-left: -5px;
            margin-top: 20px;
            .swiper-pagination-bullet-active{
                background-color: #81a910;
                width: 12px;
                height: 12px;
            }
        }
        .badge-item{
            background-color: #81a910;
            color: #fff;
            width: 130px;
            height: 130px;
            border-radius: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-right: 40px;
            margin-bottom: 40px;
            .icon{
                width: 35px;
                height: 35px;
                margin-bottom: 10px;
                &.cred{
                    margin-top: -15px;
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            .text{
                font-size: 12px;
                text-align: center;
                text-transform: uppercase;
            }
        }
    }
}