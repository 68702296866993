.work-stage{
    padding-top: 100px;
    padding-bottom: 100px;
    .title{
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 50px;
        .small-text{
            font-size: 16px;
        }
        .big-text{
            font-weight: bold;
            font-size: 32px;
        }
    }

    .percent-circle{
        background-color: #81a910;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        padding: 10px;
        text-align: center;
        line-height: 1.2;
        margin: auto;
        margin-bottom: 50px;
        .number{
            font-weight: bold;
            font-size: 26px;
        }
        .text{
            text-transform: uppercase;
            font-size: 14px;
        }
    }

    .percents-listing{
        @media (min-width: 1200px){
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
        }
        .percent-item{
            margin-bottom: 30px;
            @media (min-width: 1200px){
                width: 20%;
                margin-right: 5%;
                margin-bottom: 60px;
                &:nth-child(4), &:nth-child(6){
                    margin-right: 0;
                }
            }
            .top-line{
                display: flex;
                justify-content: space-between;
                font-size: 14px;
                margin-bottom: 5px;
            }
            .line-container{
                width: 100%;
                height: 8px;
                position: relative;
                border-radius: 8px;
                overflow: hidden;
                .line{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    background-color: #e1e1e1;
                }
                .line-fill{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    background-color: #de7930;
                    border-radius: 8px;
                }
            }
        }
    }
}