.main-menu {
    @include media(get-bp(desktop), max) {
        display: none;
    }

    @include media(get-bp(desktop)) {
        display: flex;
    }

    .main-menu__list {
        display: flex;
        font-size: 1.6rem;
    }

    .main-menu__item,
    .menu-item {
        margin-right: 2.5rem;
        transition: 0.2s;
        color: white;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
        img{
            width: 30px;
        }
        &:last-of-type {
            margin-right: 0;
        }
        &:hover {
            opacity: 0.8;
            
        }
    }
}

.main-search-bar-toggle {
    padding-top: 0;
    padding-bottom: 0;
    margin-left: 2rem;
}
