.detail-video-section{
    padding-top: 50;
    .title{
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 50px;
        .small-text{
            font-size: 16px;
        }
        .big-text{
            font-weight: bold;
            font-size: 32px;
        }
    }

    .video-container{
        width: 100%;
        height: 450px;
        @media (min-width: 1200px){
            height: 600px;
        }
        iframe{
            width: 100%;
            height: 100%;
        }
    }
}