

.content-pages {
    h2 {
        margin-top: 80px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        @include  media(get-bp(desktop)) {
            text-align: left;
            flex-direction: row;
        }

        a {
            margin-top: 80px;
            background-color: #015b2c;
            color: #fff;
            border-radius: 5px;
            font-weight: 400;
            padding: 15px 25px;
            font-size: 14px;
            transition: 0.3s;
            overflow: hidden;
            position: relative;
            cursor: pointer;

            &::before {
                animation-name: call;
                animation-duration: 4s;
                content: "";
                width: 3px;
                height: 200px;
                background: #fff;
                filter: blur(4px);
                display: block;
                position: absolute;
                top: -40px;
                transform: rotate(45deg);
                animation-iteration-count: infinite;
                cursor: pointer;
                pointer-events: none;
            }

            @include  media(get-bp(desktop)) {
                margin-top: 0px;
            }
            &:hover {
                background-color: #000;
            }
            span {
                display: block;
                margin-top: 5px;
            }
        }
    }

    hr {
        margin-bottom: 80px;
        opacity: 0.18;
        display: none;
        @include  media(get-bp(desktop)) {
           display: block;
        }
    }
}

.avia_codeblock {
    max-width: 80%;
    margin: 0 auto;
    form > div {
        margin-top: 50px;
    }

    .flex-telefone {
        display: flex;
        justify-content: space-between;
        input:first-child {
            margin-right: 15px;
        }
    }

    label {
        display: block;
        font-weight: 600;
    }

    input, textarea, .uploadText {
        display: block;
        width: 100%;
        border: 0.5px #000;
        padding: 15px 20px;
        background: #f5f5f5;
        outline: none;
    }
    .uploadText {
        font-weight: 400;
    }

    #fileUpload {
        display: none;
    }

    .button {
        background-color: #015b2c;
        color: #fff;
        font-weight: 600;
        max-width: 300px;
        margin-bottom: 80px;
        transition: 0.2s;
        &:hover {
            background-color: #000;
        }
    }
}

.content-lado-lado {
    @include  media(get-bp(desktop)) {
        display: grid;
        grid-template-columns:calc( 50% - 10px) calc( 50% - 10px) ;
        grid-column-gap: 20px;
    }
}
// .part-form {
//     visibility: hidden;
//     opacity: 0;
//     display: none;

//     &.is-active {
//         transition: 0.2s;
//         display: block;
//         visibility: visible;
//         opacity: 1;
//     }
// }
@keyframes call {
    0% {
        transform: translateX(-300px) rotate(45deg);
    }

    100% {
        transform: translateX(300px) rotate(45deg);
    }
}
