.detail-navigation-bar{
    padding-top: 80px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 30;
    width: 100%;
    color: #fff;
    transition: 1.4s;
    display: none;
    @media (min-width: 1200px){
        display: block;
    }
    &.active{
        position: fixed;
        top: -1px;
        .container{
            padding: 0;
            margin: 0;
            max-width: unset;
            // padding: 20px;
            padding: 10px;
            padding-left: 30px;
            padding-right: 30px;
        }
    }
    .container{
        display: flex;
        justify-content: space-between;
        // padding: 20px;
        padding: 13px;
        background-color: #000;
        align-items: center;
        
        img {
            max-width: 62px;
            object-fit: cover;
            .navigation-item{
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 600;
                
            }
        }

        .navigation-item {
            &:first-child {
                //Remover o caso tirem a imagem no back
                display: none !important;
            }

            &.is-active {
                border-bottom: 1px solid #ffffff4d;
            }
        }
    }
}