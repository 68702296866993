.datasheet{
    background-color: #f7f7f7;
    padding-bottom: 50px;
    @media (min-width: 1200px){
        padding-bottom: 150px;
        padding-top: 50px;
    }
    .title{
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 50px;
        .small-text{
            font-size: 16px;
        }
        .big-text{
            font-weight: bold;
            font-size: 32px;
        }
    }

    .flex-container{
        @media (min-width: 1200px){
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .photo{
            width: 100%;
            height: 450px;
            position: relative;
            margin-bottom: 60px;
            display: none;
            @media (min-width: 1200px){
                display: block;
                margin-bottom: 0;
                width: 350px;
                height: 500px;
                margin-right: 60px;
            }
            .square{
                width: 50%;
                height: 90%;
                background-color: #ac9d8a;
                position: absolute;
                top: -30px;
                left: -30px;
            }

            .image{
                width: 100%;
                height: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        .text{
            width: 100%;
            @media (min-width: 1200px){
                width: 400px;
            }

            .data-item{
                margin-bottom: 20px;
                display: flex;
                font-size: 16px;
                .data-item-title{
                    font-weight: bold;
                    margin-right: 5px;
                }
            }
        }
    }
}

