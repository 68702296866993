.tabs-slider-section{
    background-color: #000;
    height: 550px;
    position: relative;
    z-index: 5;
    .tab-header{
        margin-bottom: 30px;
        position: absolute;
        bottom: 20px;
        left: 0;
        z-index: 2;
        .container{
            @media (min-width: 1200px){
                display: flex;
            }
        }

        .tab-header-item{
            background-color: transparent;
            text-transform: uppercase;
            color: rgba(#fff, 0.6);
            padding: 5px 20px;
            border-radius: 1px;
            &:hover{
                color: #85ad11;
            }
            &.is-active{
                background-color: #85ad11;
                color: #fff;
            }
        }
    }

    .tab-content{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border: 0;
        .tab-content-item{
            width: 100%;
            height: 100%;
        }
        .tab-slide-zero, .tab-slide-one, .tab-slide-two, .tab-slide-three{
            width: 100%;
            height: 100%;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}