.modal-email {
    .modal__header {
        border: none !important;
    }

    .modal__box {
        max-width: 400px;
        border-radius: 20px;
    }
    .modal__title {
        text-align: center;
        width: 100%;
    }
    svg {
        background: #000;
        border-radius: 5px;
        fill: #fff;
        transition: 0.3s;
        &:hover {
            background: green !important;
        }
    }
    .line-form {
        display: grid;
        grid-template-columns: 100%;
        grid-column-gap: 15px;
        justify-content: space-between;
        @include media(get-bp(desktop)) {
            grid-template-columns: calc(50% - 7.5px) calc(50% - 7.5px);
        }
    }

    label {
        display: block;
        margin-top: 10px;
        margin-bottom: 5px;
    }

    input, textarea,select  {
        width: 100%;
        margin-bottom: 10px;
        display: block;
        background-color: #ededed;
        border: none;
        padding: 10px;
        outline: none !important;
    }

    textarea {
        height: 100px;
    }

    input[type="submit"]{
        background: green;
        color: #fff;
        max-width: 110px;
        margin: 20px auto;
        border-radius: 6px;
        transition: 0.3s;
        &:hover {
            background: #000;
        }
    }
}