.detail-main-banner{
    width: 100%;
    position: relative;
    overflow: hidden;
    height: 90vh;
    z-index: 4;
    @media (min-width: 1200px){
        // height: 650px;

    }
    .overlay{
        background-color: rgba(#000, 0.5);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
    }
    .video-container{

        position: absolute;
        top: 0;
        left: 0;
        right: 0;

        .border-video{
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            display: none;

            @include media(get-bp(desktop)) {
                display: block;
            }

            video {
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
                object-fit: cover;
            }
        }

        .mobile-image{
            width: 100%;
            height: 100vh;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            @media (min-width: 1200px){
                display: none;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

    }
    // .video-container{
    //     width: 100%;
    //     height: 100%;
    //     position: relative;
        
    //     iframe{
    //         width: 100%;
    //         height: 100%;
    //         position: absolute;
    //         top: 0;
    //         left: 0;
    //         transform: scale(3);
    //         display: none;
    //         @media (min-width: 1200px){
    //             display: block;
    //         }
    //     }
        


    // }

    .main-image{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .video-container {
        width: 100%;
        height: 100%;
    }

    .text-container{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
        .container{
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            @media (min-width: 1200px){
                display: flex;
                flex-direction: row;
            }
            .left{
                @media (min-width: 1200px){
                    margin-right: 150px;
                }
            }
            .right{
                text-align: center;
                cursor: pointer;
                @media (min-width: 1200px){
                    display: block;
                }
                &:hover{
                    .icon{
                        transform: scale(1.1);
                    }
                }
                .icon{
                    transition: 0.4s;
                    font-size: 70px;
                    color: #fff;
                    @media (min-width: 1200px){
                        font-size: 120px;
                    }
                }
                .text{
                    color: #fff;
                    text-transform: uppercase;
                    font-size: 14px;
                    font-weight: bold;
                }
            }
            .title{
                color: #fff;
                text-transform: uppercase;
                font-size: 28px;
                font-weight: bold;
                line-height: 1.2;
                letter-spacing: 3px;
                margin-bottom: 20px;
                text-align: center;
                @media (min-width: 1200px){
                    font-size: 92px;
                    text-align: left;
                }
            }
            .subtitle{
                text-transform: uppercase;
                font-size: 16px;
                font-weight: 500;
                color: #fff;
                width: 250px;
                text-align: center;
                margin-bottom: 30px;
                @media (min-width: 1200px){
                    font-size: 26px;
                    text-align: left;
                    margin-bottom: 0;
                    width: 400px;
                }
            }
        }
    }
}