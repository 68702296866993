.index-footer {
    background-color: #272525;
    padding: 1.2rem;
    text-align: center;
    .top-text{
        font-size: 12px;
        color: #ccc;
        text-align: center;
    }
}

.index-footer__logo {
    display: block;
    margin: auto;
    width: 8rem;
}
