.footer-content{
   padding-top: 40px;
   background-color: #f7f7f7;
    .logo-container{
        text-align: center;
        margin-bottom: 50px;
        margin-left: -15px;
        img{
            filter: invert(1);
            width: 200px;
        }
    }

    .contacts-row{
        padding-bottom: 40px;
        .container{
            background-color: #ededed;
            padding-top: 40px;
            padding-bottom: 40px;
            @media (min-width: 1200px){
                padding: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-bottom: 0;
            }
            .contact-item{
                margin-bottom: 30px;
                text-transform: uppercase;
                @media (min-width: 1200px){
                    border-right: 1px solid #000;
                    padding-right: 50px;
                    margin-right: 50px;
                    padding-top: 30px;
                    padding-bottom: 30px;
                    &:last-child{
                        padding-right: 0;
                        margin-right: 0;
                        border: 0;
                    }
                }
                .up-text{
                    font-size: 12px;
                    font-weight: 100;
                    margin-bottom: 5px;
                    i{
                        margin-right: 10px;
                        font-size: 16px;
                    }
                }
                .down-text{
                    font-weight: bold;
                    font-size: 22px;
                }
            }
        }
    }


    .about-footer{
        background-color: #f7f7f7;
        padding-top: 50px;
        padding-bottom: 50px;
        .container{
            @media (min-width: 1200px){
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .column{
                margin-bottom: 30px;
                @media (min-width: 1200px){
                    padding-top: 30px;
                }
                &.diagonal{
                    padding-top: 0;
                }
                .title{
                    text-transform: uppercase;
                    font-size: 16px;
                    line-height: 1.2;
                    margin-bottom: 10px;
                    &.diagonal{
                        font-weight: bold;
                        font-size: 20px;
                        line-height: 1;
                        @media (min-width: 1200px){
                            font-size: 32px;
                        }
                    }
                }
                .column-item{
                    display: block;
                    margin-bottom: 5px;
                    font-size: 14px;
                }

                .link{
                    transition: 0.4s;
                    &:hover{
                        opacity: 0.8;
                    }
                }
                .social-row{
                    display: flex;
                    margin-bottom: 20px;
                    .social-item{
                        display: block;
                        font-size: 32px;
                        margin-right: 20px;
                    }
                }

                .address{
                    font-size: 14px;
                }

                .input-item{
                    display: flex;
                    .send{
                        background-color: #015b2c;
                        color: #fff;
                        width: 40px;
                        height: 40px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-left: -5px;
                        cursor: pointer;
                        &:hover{
                            opacity: 0.8;
                        }
                    }
                    input{
                        flex: 1;
                        border: 0;
                        background-color: #fff;
                        padding-left: 10px;
                        font-size: 14px;
                        outline: none;
                    }
                }
            }
        }
    }
}