.video-section{
    width: 100%;
    height: 500px;
    position: relative;
    display: none;
    overflow: hidden;
    @media (min-width: 1200px){
        height: 650px;
        display: block;
    }
    .overlay{
        background-color: rgba(#28a72b, 0.6);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
    }
    .video-container{
        width: 100%;
        height: 100%;
        position: relative;
        iframe{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            transform: scale(2.5);
        }
    }

    .text-container{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
        .container{
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            .title{
                color: #fff;
                text-transform: uppercase;
                font-size: 42px;
                font-weight: bold;
                line-height: 1.2;
                @media (min-width: 1200px){
                    font-size: 72px;
                    margin-right: 80px;
                }
            }

            .right{
                text-align: center;
                cursor: pointer;
                @media (min-width: 1200px){
                    display: block;
                }
                &:hover{
                    .icon{
                        transform: scale(1.1);
                    }
                }
                .icon{
                    transition: 0.4s;
                    font-size: 70px;
                    color: #fff;
                    @media (min-width: 1200px){
                        font-size: 120px;
                    }
                }
                .text{
                    color: #fff;
                    text-transform: uppercase;
                    font-size: 14px;
                    font-weight: bold;
                }
            }
        }
    }
}