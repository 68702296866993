.mission{
    width: 100%;
    height: 1000px;
    position: relative;
    @media (min-width: 1200px){
        padding-top: 120px;
        height: 820px;
    }
    .slice{
        width: 100%;
        height: 120px;
        background-color: #fff;
        position: absolute;
        top: -30px;
        left: 0px;
        z-index: 2;
        transform: rotate(1deg);
        display: none;
        @media (min-width: 1200px){
            display: block;
        }
    }
    .bg-image{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .container{
        color: #fff;
        padding-top: 50px;
        padding-bottom: 50px;
        @media (min-width: 1200px){
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            font-size: 16px;
            align-items: center;
        }
        .right-side{
            @media (min-width: 1200px){
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 40%;
            }
            .title{
                font-weight: 900;
                
                font-size: 35px;
                text-transform: uppercase;
                line-height: 1.1;
                margin-bottom: 60px;
                padding-left: 30PX;
                @media (min-width: 1200px){
                    text-align: center;
                    font-size: 82px;
                    padding-left: 0;
                }
            }
            .text-content{
                display: none;
                @media (min-width: 1200px){
                    display: block;
                }
                .small-title{
                    text-align: center;
                    font-size: 22px;
                    margin-bottom: 20px;
                }
                .text-item{
                    margin-bottom: 20px;
                    font-size: 16px;
                }
            }
        }

        .left-side{
            @media (min-width: 1200px){
                width: 50%;
            }
            .content-item{
                margin-bottom: 40px;
                @media (min-width: 1200px){
                    width: 400px;
                }
                .title{
                    text-transform: uppercase;
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                    .line{
                        width: 20px;
                        height: 2px;
                        background-color: #fff;
                        margin-right: 10px;
                    }
                }

                .content-text{
                    font-size: 16px;
                    padding-left: 30px;
                }
            }
        }
    }
}