.building-details{
    padding-top: 60px;
    .title-row{
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        justify-content: center;
        .left{
            display: flex;
            align-items: center;
            width: 70%;
            @media (min-width: 1200px){
                margin-right: 20px;
                width: unset;
            }
            .pin{
                width: 20px;
                margin-right: 10px;
                @media (min-width: 1200px){
                    width: 40px;
                    margin-right: 20px;
                }
            }
            .title{
                font-weight: bold;
                text-transform: uppercase;
                font-size: 16px;
                @media (min-width: 1200px){
                    font-size: 40px;
                }
            }
        
        }
        .right{
            text-align: left;
            flex: 1;
            @media(min-width: 1200px){
                flex: unset;
            }
            .map{
                display: block;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 14px;
                line-height: 1.2;
                color: #b1b1b1;
                @media (min-width: 1200px){
                    font-size: 20px;
                }
            }
        }
    }
    
    .icons-listing{
        margin-bottom: 60px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @media (min-width: 1200px){
           justify-content: center;
           margin-top: 90px;
        }
        .icon-item{
            text-align: center;
            width: 100px;
            margin-bottom: 30px;
            margin-right: 10px;
            @media (min-width: 1200px){
                border-right: 1px solid #ccc;
                width: 150px;
                &:last-child{
                    border-right: 0;
                }
                &.tour{

                   
                    margin-right: 0;
                    border: 0;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: -30px;
                    width: 300px;
                    position: relative;


                    &:hover .submenu {
                        visibility: visible;
                        opacity: 1;
                    }
                    .submenu {
                        position: absolute;
                        bottom: -86px;
                        left: 0%;
                        list-style: none;
                        padding: 0;
                        width: 100%;
                        visibility: hidden;
                        opacity: 0;
                        transition: 0.3s;
                        li {
                            width: 100%;
                        }
                        li a{
                           background-color: #403f3f;
                           display: block;
                           width: 100%;
                           padding: 10px;
                           color: #fff;
                           text-align: left;
                           position: relative;

                           &::before {
                               content: "";
                               height: 5px;
                               position: absolute;
                               left: 10px;
                               width: 0;
                               bottom: 3px;
                               background: #01773a;
                               transition: 0.5s;
                           }

                           &:hover:before {
                               width: 90%;
                           }
                        }
                    }
                    @media (min-width: 1200px){
                        width: 150px;
                    }
                    .image-container{
                        width: 100px;
                        height: 100px;
                        background-color: #01773a;
                        padding: 20px;
                        margin: auto;
                        margin-left: 50px;
                        position: relative;
                        z-index: 2;
                        img{
                            filter: invert(1) brightness(30);
                        }
                    }

                    .text{
                        background-color: #015a2c;
                        padding: 30px;
                        width: 200px;
                        height: 80px;
                        margin-top: -20px;
                        color: #fff;
                        font-weight: bold;
                        position: relative;
                    }
                }
            }
            .image-container{
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: auto;
                margin-bottom: 20px;
                @media (min-width: 1200px){
                    width: 70px;
                    height: 70px;
                }
                &.car{
                    @media (min-width: 1200px){
                        width: 90px;
                        height: 90px;
                        padding-top: 20px;
                        margin-top: -20px;
                    }
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    // filter: brightness(0) grayscale(1);
                    // opacity: 0.5;
                }
            }

            .text{
                text-transform: uppercase;
                font-size: 14px;
                line-height: 1.2;
                text-align: center;
                margin: auto;
                &.blue{
                    color: #0083e3;
                    font-weight: bold;
                }
            }
        }
    }
}

.tour.is-mobile {
    position: relative;
    width: 100% !important;

    .image-container {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 9;
    }

    .text {
        padding: 10px;
        display: flex;
        justify-content: space-between;
        background: #01763a;
        align-items: center;
        color: #fff;
        font-size: 17px;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            height: 100%;
            right: 0;
            top: 0;
            width: 53px;
            background: #01522a;
        }

        img {
            max-width: 30px;
            max-width: 30px;
            filter: brightness(0) invert(1);
        }
    }

    ul {
        width: 100%;
        list-style: none;
        padding: 0;
        margin-top: 0;
    }

    li {
        background-color: #403f3f;
        display: block;
        width: 100%;
        padding: 10px;
        color: #fff;
        text-align: left;
        position: relative;
    }

    li a{
        background-color: #403f3f;
        display: block;
        width: 100%;
        padding: 10px;
        color: #fff;
        text-align: left;
        position: relative;

        &::before {
            content: "";
            height: 5px;
            position: absolute;
            left: 10px;
            width: 50px;
            bottom: 3px;
            background: #01773a;
            transition: 0.5s;
        }

        &:hover:before {
            width: 90%;
        }
    }
}


.is-mobile {
    @media (min-width: 1200px){
        display: none !important;
    }
}


.icon-item.tour.is-desktop {
    display: none;
    @media (min-width: 1200px){
        display: block;
    }
}