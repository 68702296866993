.technical-details{
    position: relative;
    background-color: #191817;
    padding-top: 50px;
    overflow: hidden;
    width: 100%;
    padding-bottom: 50px;
    @media (min-width: 1200px){
        height: 950px;
        padding-top: 150px;
    }
    .advantages-top-slice{
        width: 150%;
        height: 200px;
        background-color: #fff;
        position: absolute;
        top: -120px;
        left: 0;
        z-index: 2;
        transform: rotate(2deg);
        display: none;
        @media (min-width: 1200px){
            display: block;
        }
    }
    .advantages-listing{
        color: #fff;
        @media (min-width: 1200px){
            display: flex;
        }
        .left{
            @media (min-width: 1200px){
                width: 400px;
            }
        }
        .right{
            @media (min-width: 1200px){
                padding-left: 150px;
            }
        }
        .advantage-item{
            position: relative;
            margin-bottom: 20px;
            padding-top: 5px;
            @media (min-width: 1200px){
                display: block;
                margin-bottom: 40px;
                padding-top: 0;
            }
            .top-text{
                font-size: 12px;
                text-transform: uppercase;
                font-weight: bold;
                margin-bottom: 5px;
                @media (min-width: 1200px){
                   font-size: 18px;
                }
            }
            .content-text{
                font-size: 14px;
               
            }
            &:after{
                content: '';
                width: 26px;
                height: 1px;
                background-color: #fff;
                position: absolute;
                top: 0;
                left: 0px;
                @media (min-width: 1200px){
                    top: 12px;
                    left: -40px;
                }
            }
        }
    }
    .photo{
        
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        @media (min-width: 1200px){
            right: -200px;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .title-row{
        line-height: 1.2;
        padding-top: 10px;
        margin-bottom: 60px;
        color: #fff;
        @media (min-width: 1200px){
           margin-bottom: 100px;
        }
        .small-text{
            text-transform: uppercase;
            font-size: 14px;
            @media (min-width: 1200px){
                font-size: 22px;
            }
        }
        .big-text{
            font-size: 20px;
            text-transform: uppercase;
            font-weight: bold;
            @media (min-width: 1200px){
                font-size: 42px;
            }
        }
        &:after{
            content: '';
            width: 46px;
            height: 1px;
            background-color: #ccc;
            position: absolute;
            top: 0;
            left: 20px;
            @media (min-width: 1200px){
                top: 22px;
                left: -40px;
            }
        }
    }

    .slide-title{
        text-transform: uppercase;
        font-weight: bold;
        font-size: 32px;
        margin-bottom: 20px;
    }


    .places-slider-container{
        position: relative;
        @media (min-width: 1200px){
            width: 500px;
            height: 350px;

        }
        .box{
            width: 100%;
            height: 100%;
            background-color: #ee9903;
            position: absolute;
            top: 10px;
            left: 10px;
            @media (min-width: 1200px){
                height: 85%;
            }
        }
        .slides-control{
            display: flex;
            position: absolute;
            bottom: 10px;
            right: 10px;
            z-index: 2;
            @media (min-width: 1200px){
                bottom: 70px;
            }
            .arrow{
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 10px;
                cursor: pointer;
                i{
                    transition: 0.4s;
                }
                &:hover{
                    i{
                        transform: scale(1.2);
                    }
                }
                &.back-arrow{
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 30px 40px 30px 0;
                    border-color: transparent #e27d7d transparent transparent;
                    i{
                        margin-left: 50px;
                    }

                }
                &.next-arrow{
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 30px 0px 30px 40px;
                    border-color: transparent transparent transparent #e27d7d;
                    i{
                        margin-right: 50px;
                    }

                }
                
            }
        }
        .swiper-wrapper{
            width: 100%;
            height: 100%;
        }
        .swiper-slide{
            width: 100%;
            height: 300px;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}