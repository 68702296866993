.desktop-floating-chat{
    display: none;
    position: fixed;
    right: 30px;
    bottom: 120px;
    padding: 10px 20px;
    background-color: #000;
    border-radius: 30px;
    color: #fff;
    z-index: 10;
    text-transform: uppercase;
    font-size: 14px;
    transition: 0.1s;
    &:hover{
        border-radius: 1px;
        .hover-content{
            display: block;
            .chat-item{
                font-size: 12px;
                font-weight: 600;
                margin-top: 20px;
                margin-left: 5px;
                &:first-child{
                    margin-top: 0;
                }
            }
        }
    }
    .hover-content{
        display: none;
        transition: 0.4s;
        position: absolute;
        left: 0;
        background-color: #000;
        width: 100%;
        top: 49px;
        padding: 15px;
    }
    .chat-item{
        display: inline-flex;
        font-size: 14px;
        font-weight: bold;
        align-items: center;
        cursor: pointer;
        margin-top: 5px;
        padding: 0;
        &:hover{
            text-decoration: underline;
        }
        img{
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }
    }
    @media (min-width: 1200px){
        display: inline-flex;
        display: block;
    }
}