.galleries-listing{
    margin-bottom: 50px;
    .galleries-slider{
        padding-bottom: 50px;
    }
    .gallery-item{
        position: relative;
        cursor: pointer;
        &:hover{
            .overlay{
                opacity: 1;
            }
        }
        .photo{
            width: 100%;
            height: 300px;
            position: relative;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            
        }
        .overlay{
            width: 100%;
            height: 300px;
            background-color: rgba(black, 0.4);
            display: flex;
            opacity: 0;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            transition: 0.4s;
            color: #fff;
            font-size: 32px;
        }
        .text{
            background-color: #000;
            color: #fff;
            padding: 20px;
            text-align: center;
            text-transform: uppercase;
            font-size: 12px;
        }
    }

    .galleries-pagination{
        @media (min-width: 1200px){
            display: none;
        }
    }
}



.modal{
    .modal__title{
        font-size: 22px;        
        font-weight: bold;
    }

   .photo-item{
        width: 100%;
        height: 450px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
   }
}