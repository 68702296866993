.localization-section{
    padding-top: 50;
    background-color: #f7f7f7;
    @media (min-width: 1200px){
        padding-top: 80px;
    }
    .title{
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 50px;
        .small-text{
            font-size: 16px;
        }
        .big-text{
            font-weight: bold;
            font-size: 32px;
        }
    }

    .map-image{
        width: 100%;
        height: 350px;
        display: block;
        @media (min-width: 1200px){
            height: 600px;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}