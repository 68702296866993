.contact-block{
    display: flex;
    flex-wrap: wrap;
    transition: 0.4s;
    position: relative;
    z-index: 3;
    @media (min-width: 1200px){
        flex-direction: column;
        position: fixed;
        top: 325px;
        right: 0;
        z-index: 3;
        transform: translateX(100px);
    }
    &:hover{
        transform: translateX(0);
    }
    .block-item{
        width: 50%;
        display: inline-flex;
        align-items: center;
        padding: 20px;
        color: #fff;
        text-transform: uppercase;
        font-size: 14px;
        position: relative;
        overflow: hidden;
        .block-slice{
            background-color: rgba(#eee, 0.2);
            width: 200%;
            height: 35px;
            position: absolute;
            bottom: -30px;
            left: -20px;
            z-index: 2;
            transform: rotate(8deg);
        }

        .block-slice-small{
            background-color: rgba(#fff, 0.1);
            width: 150%;
            height: 60px;
            position: absolute;
            top: -15px;
            left: -50px;
            z-index: 2;
            transform: rotate(-5deg);
        }
        &:hover{
            text-decoration: underline;
            .main-text{
                opacity: 0;
                display: none;
            }
            .hover-text{
                display: block;
                opacity: 1;
            }
        }
        @media (min-width: 1200px){
           width: 150px;
           font-size: 10px;
           height: 50px;
           overflow: hidden;
        }

        .main-text{
            transition: 0.4s;
        }

        .hover-text{
            transition: 0.4s;
            opacity: 0;
            display: none;
        }
        i{
            margin-right: 10px;
            width: 20px;
            font-size: 16px;
            line-height: 1;
        }
        &.blue{
            background-color: #088bc5;
        }
        &.green{
            background-color: #85ad11;
        }
        &.orange{
            background-color: #de7930;
        }
        &.gold{
            background-color: #ee9903;
        }

    }
}



.contato-page{
    margin-top: 150px;
}

.page-cliente {
    small {
        margin-bottom: 90px;
    }
    p {
        max-width: 60%;
    }
    ul {
        list-style: none;
        padding: 0;

        li:before{
            content: '';
            display: inline-block;
            width: 20px;
            height: 20px;
            background-image: url("../images/accept.svg");
            margin-right: 10px;
            background-size: cover;
         }
    }
}