.advantages-container{
    position: relative;
    background-color: #191817;
    height: 600px;
    padding-top: 50px;
    overflow: hidden;
    width: 100%;
    @media (min-width: 1200px){
        height: 750px;
    }
    .advantages-bottom-slice{
        width: 150%;
        height: 200px;
        background-color: #f7f7f7;
        position: absolute;
        bottom: -120px;
        left: 0;
        z-index: 2;
        transform: rotate(-2deg);
        display: none;
        @media (min-width: 1200px){
            display: block;
        }
    }
    .advantages-listing{
        color: #fff;
        padding-left: 35px;
        @media (min-width: 1200px){
            display: flex;
            padding-left: 0;
        }
        .left{
            @media (min-width: 1200px){
                width: 400px;
            }
        }
        .right{
            @media (min-width: 1200px){
                padding-left: 150px;
            }
        }
        .advantage-item{
            position: relative;
            margin-bottom: 20px;
            padding-top: 5px;
            @media (min-width: 1200px){
                display: block;
                margin-bottom: 40px;
                padding-top: 0;
            }
            .top-text{
                font-size: 16px;
                text-transform: uppercase;
                font-weight: bold;
                margin-bottom: 5px;
                @media (min-width: 1200px){
                   font-size: 18px;
                }
            }
            .content-text{
                font-size: 14px;
                display: none;
                @media (min-width: 1200px){
                    display: block;
                }
            }
            &:after{
                content: '';
                width: 26px;
                height: 1px;
                background-color: #fff;
                position: absolute;
                top: 15px;
                left: -35px;
                @media (min-width: 1200px){
                    top: 12px;
                    left: -40px;
                }
            }
        }
    }
    .photo{
        
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        @media (min-width: 1200px){
            right: -200px;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .title-row{
        line-height: 1.2;
        padding-top: 10px;
        margin-bottom: 60px;
        color: #fff;
        @media (min-width: 1200px){
           margin-bottom: 100px;
            &:after{
                content: '';
                width: 46px;
                height: 1px;
                background-color: #ccc;
                position: absolute;
                top: 0;
                left: 20px;
                @media (min-width: 1200px){
                    top: 22px;
                    left: -40px;
                }
            }
        }
        .small-text{
            text-transform: uppercase;
            font-size: 20px;
            @media (min-width: 1200px){
                font-size: 22px;
            }
        }
        .big-text{
            font-size: 40px;
            text-transform: uppercase;
            font-weight: bold;
            @media (min-width: 1200px){
                font-size: 42px;
            }
        }
      
       
    }
}