.architects{
    background-color: #f7f7f7;
    padding-top: 100px;
    padding-bottom: 50px;
    @media (min-width: 1200px){
        padding-bottom: 150px;
    }
    .title{
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 50px;
        .small-text{
            font-size: 16px;
        }
        .big-text{
            font-weight: bold;
            font-size: 32px;
        }
    }

    .flex-container{
        @media (min-width: 1200px){
            display: flex;
            // align-items: center;
        }
        .photo{
            width: 200px;
            height: 150px;
            position: relative;
            margin: auto;
            margin-bottom: 60px;
            @media (min-width: 1200px){
                // margin-bottom: 0;
                width: 550px;
                height: 300px;
                margin-right: 60px;
            }
            .square{
                width: 100%;
                height: 100%;
                background-color: #ac9d8a;
                position: absolute;
                bottom: -30px;
                left: -30px;
            }

            .image{
                width: 100%;
                height: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        .text{
            flex: 1;
        }
    }
}

