.plantas-section{
    background-color: #f7f7f7;
    padding-top: 70px;
    padding-bottom: 80px;
    position: relative;
    .title{
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 50px;
        .small-text{
            font-size: 16px;
        }
        .big-text{
            font-weight: bold;
            font-size: 32px;
        }
    }

    .container{
        @media (min-width: 1200px){
            display: flex;
            align-items: center;
        }

        .left-column{
            margin-bottom: 40px;
            @media (min-width: 1200px){
                width: 30%;
                margin-bottom: 0;
                padding-right: 50px;
            }
            .tab-header{
                display: block;
                list-style: none;
                padding: 0;
                li {
                    display: flex;
                    align-items: center;

                    img {
                        max-width: 30px;
                        filter: brightness(0);
                        margin-left: 10px;

                        &:hover {
                            filter: brightness(50%);
                        }
                        
                    }
                }
                .tab-header-item{
                    background-color: #f7f7f7;
                    border: 1px solid #ac9d8a;
                    padding: 20px;
                    color: #000;
                    text-align: center;
                    width: calc(100% - 40px);
                    transition: 0.4s;
                    text-transform: uppercase;
                    font-weight: bold;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 15px;

                    &.has-video {
                        width: 100%;
                    }

                    &:hover {
                        img {
                            max-width: 30px;
                            filter: brightness(0) invert(1);
                            transition: 0.5s;
                        }
                    }

                    img {
                        max-width: 30px;
                        filter: brightness(0);
                    }
                    &:hover{
                        background-color: #ac9d8a;
                        color: #fff;
                    }
                    &.is-active{
                        background-color: #ac9d8a;
                        color: #fff;

                        img {
                            filter: brightness(0) invert(1);
                        }
                    }
                }
            }

            
        }

        .right-content{
            @media (min-width: 1200px){
                width: 75%;
                // padding-left: 10%;
            }
            .tab-content, .tab-content-item{
                border: 0 !important;
                width: 100%;
            }
            .plant-image{
                width: 100%;
                // height: 400px;
                img{
                    width: 100%;
                    @media (min-width: 1200px){
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}

