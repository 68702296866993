.entertaining-section{
    padding-top: 50px;
    .container{
        @media (min-width: 1200px){
            display: flex;
            align-items: center;
        }

        .left{
            @media (min-width: 1200px){
               width: 50%;
            }

            .small-title{
                text-transform: uppercase;
                font-weight: bold;
                padding-left: 100px;
            }
            .entertaining-listing{
                .entertaining-item{
                    margin-bottom: 10px;
                    display: flex;
                    align-items: center;
                    .icon{
                        margin-right: 20px;
                    }
                    .text-container{
                        @media (min-width: 1200px){
                            width: 250px;
                        }
                        .text{
                            text-transform: uppercase;
                            font-weight: bold;
                            font-size: 24px;
                        }
                        .line{
                            width: 100%;
                            height: 8px;
                            margin-top: -13px;
                            background-color: red;
                            &.green{
                                background-color: #85ad11;
                            }
                            &.blue{
                                background-color: #008b97;
                            }
                            &.yellow{
                                background-color: #ee9903;
                            }
                            &.rose{
                                background-color: #e27d7d;
                            }
                            &.brown{
                                background-color: #aa9583;
                            }
                        }
                    }
                }
            }
        }
    }


    .places-slider-container{
        position: relative;
        @media (min-width: 1200px){
            width: 500px;
            height: 350px;

        }
        .box{
            width: 100%;
            height: 100%;
            background-color: #ee9903;
            position: absolute;
            top: 10px;
            left: 10px;
            @media (min-width: 1200px){
                height: 85%;
            }
        }
        .slides-control{
            display: flex;
            position: absolute;
            bottom: 10px;
            right: 10px;
            z-index: 2;
            @media (min-width: 1200px){
                bottom: 70px;
            }
            .arrow{
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 10px;
                cursor: pointer;
                i{
                    transition: 0.4s;
                }
                &:hover{
                    i{
                        transform: scale(1.2);
                    }
                }
                &.entert-back-arrow{
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 30px 40px 30px 0;
                    border-color: transparent #e27d7d transparent transparent;
                    i{
                        margin-left: 50px;
                    }

                }
                &.entert-next-arrow{
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 30px 0px 30px 40px;
                    border-color: transparent transparent transparent #e27d7d;
                    i{
                        margin-right: 50px;
                    }

                }
                
            }
        }
        .swiper-wrapper{
            width: 100%;
            height: 100%;
        }
        .swiper-slide{
            width: 100%;
            height: 300px;
            position: relative;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .tag{
                position: absolute;
                bottom: 20px;
                left: 0;
                z-index: 3;
                padding: 5px 20px;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 12px;
                @media (min-width: 1200px){
                    font-size: 16px;
                }
                &.green{
                    background-color: #85ad11;
                }
                &.blue{
                    background-color: #008b97;
                }
                &.yellow{
                    background-color: #ee9903;
                }
                &.rose{
                    background-color: #e27d7d;
                }
                &.brown{
                    background-color: #aa9583;
                }
                
            }
        }
    }
}