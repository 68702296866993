.home-top-slider-container{
    width: 100%;
    height: 100vh;
    position: relative;
    z-index: 5;
    .image-slider-container{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        .top-image-slider{
            width: 100%;
            height: 100%;
            .slide-item{
                width: 100%;
                height: 100%;
                position: relative;
                .image-item{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .text-container{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    .container{
                        display: flex;
                        width: 100%;
                        height: 100%;
                        align-items: center;
                        padding-left: 30px;
                        @media (min-width: 1200px){
                            padding-left: 150px;
                        }

                        .text-content{
                            width: 200px;
                            position: relative;
                            color: #fff;
                            @media (min-width: 1200px){
                                color: #f1b4b7;
                                width: 600px;
                                margin-left: 100px;
                                margin-top: 150px;
                            }

                            @media (min-width: 1600px){
                                margin-top: 40px;
                            }
                            .slice-detail{
                                width: 1000px;
                                position: absolute;
                                top: -90%;
                                left: -55%;
                                z-index: 3;
                                display: none;
                                @media (min-width: 1200px){
                                    display: block;
                                }
                            }
                            .title{
                                text-transform: uppercase;
                                font-weight: bold;
                                font-size: 52px;
                                line-height: 1;
                                margin-bottom: 20px;
                                position: relative;
                                z-index: 4;
                                @media (min-width: 1200px){
                                   font-size: 72px;
                                }
                            }
                            .subtitle{
                                text-transform: uppercase;
                                font-size: 18px;
                                line-height: 1.2;
                                margin-bottom: 20px;
                                position: relative;
                                z-index: 4;
                                font-weight: bold;
                                @media (max-width: 1200px){
                                    margin: 36px auto;
                                 }
                                @media (min-width: 1200px){
                                    width: 50%;
                                    font-size: 20px;
                                }
                            }
                            .description{
                                position: relative;
                                z-index: 4;
                                text-transform: uppercase;
                                font-size: 18px;
                                margin-bottom: 40px;
                                font-weight: bold;
                                @media (min-width: 1200px){
                                    font-size: 20px;
                                    margin-bottom: 40px;
                                }
                            }

                            
                            .see-more{
                                position: relative;
                                z-index: 4;
                                color: #fff;
                                text-transform: uppercase;
                                padding: 10px 20px;
                                font-size: 20px;
                                border-radius: 6px;
                                transition: 0.4s;
                                background-size: cover;
                                width: max-content;
                                @media (min-width: 1200px){
                                    font-size: 12px;
                                    padding: 15px 30px;
                                    letter-spacing: 3px;
                                }
                                &:hover{
                                    opacity: 0.8;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    

    .overlay{
        width: 100%;
        height: 100%;
        background-color: rgba(black, 0.2);
        position: absolute;
        top: 0;
        left: 0;
       
    }

   

   .slide-control{
       position: absolute;
       top: 55%;
       z-index: 30;
       display: none;
       @media (min-width: 1200px){
           top: 35%;
           display: block;
       }
       &.slide-prev{
            left: 20px;
            cursor: pointer;
            .content{
                color: #fff;
                font-size: 24px;
            }
            &.hide{
                transform: translateX(-100%);
            }
            @media (min-width: 1200px){
                left: 0px;
                border-top: 80px solid transparent;
                border-bottom: 80px solid transparent;
                border-left: 80px solid #000;
                transition: 0.6s;
                &:hover{
                    opacity: 0.6;
                }
                .content{
                    transition: 0.4s;
                    background-color: #000;
                    font-size: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    margin-left: -100px;
                    
                }
            }
       }
       &.slide-next{
            right: 20px;
            cursor: pointer;
            .content{
                color: #fff;
                font-size: 24px;
            }
            &.hide{
                transform: translateX(100%);
            }
            @media (min-width: 1200px){
                right: 0px;
                border-top: 80px solid transparent;
                border-bottom: 80px solid transparent;
                border-right: 80px solid #000;
                transition: 0.6s;
                &:hover{
                    opacity: 0.6;
                }
                .content{
                    transition: 0.4s;
                    background-color: #000;
                    font-size: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    margin-right: -100px;
                    
                }
            }
       }
   }


   .swiper-pagination-bullet{
       width: 10px;
       height: 10px;
       border-radius: 50%;
       position: relative;
       z-index: 99;
       transition: 0.4s;
       @media (min-width: 1200px){
           width: 25px;
           height: 25px;
       }
       &.swiper-pagination-bullet-active{
           background: #fff;
           @media (min-width: 1200px){
           }
       }
   }
}