// #surroundings {
//     position: relative;

//     H2 {
//         position: absolute;
//         top: 50PX;
//         left: 50%;
//         transform: translateX(-50%);
//         z-index: 9;
//         background: #fff;
//         padding: 20px;
//     }

//     ul {
//         padding: 0px;
//         margin: 0px;
//         list-style: none;
//     }
    
//     img {
//         display: block;
//         max-width: 100%;
//     }
    
//     .slide-wrapper {
//         overflow: hidden;
//     }
    
//     .slide {
//         display: flex;
//         li {
//             width: 100%;
//             height: 100vh;
//             background: #000;
//         }
//         img {
//             object-fit: cover;
//             height: 100%;
//             width: 100%;
//             opacity: 0.5;
//         }
//     }
    
//     .slide:hover {
//         will-change: transform;
//     }
    
//     .slide li {
//         flex-shrink: 0;
//         max-width: 100%;
//         margin: 0 20px;
//         border-radius: 4px;
//         overflow: hidden;
//         // box-shadow: 0 2px 4px rgba(0,0,0,.4);
//         opacity: .8;
//         transform: scale(.8);
//         transition: .4s;
//     }
    
//     .slide li.active {
//         opacity: 1;
//         transform: scale(1);
//     }
    
//     [data-control="slide"] {
//         display: flex;
//         justify-content: center;
//         margin-top: 20px;
//     }
    
//     [data-control="slide"] li a {
//         display: block;
//         width: 12px;
//         height: 12px;
//         background: #FB5;
//         border-radius: 50%;
//         overflow: hidden;
//         text-indent: -999px;
//         margin: 5px;
//     }
    
//     [data-control="slide"] li.active a, [data-control="slide"] li a:hover {
//         background: #E54;
//     }
    
//     .custom-controls li{
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         width: 100%;
//     }

    
//     .custom-controls li span{
//         opacity: .8;
//         transform: scale(.8);
//         overflow: hidden;
//         margin: 2px;
//         // box-shadow: 0 2px 2px rgba(0,0,0,.5);
//         transition: .3s;
//         cursor: pointer;
//         text-transform: uppercase;
//         color: #fff;
//         font-weight: 800;
//         font-size: 25px;
//         border: 4px solid #fff;
//         padding: 20px;
//         letter-spacing: 1.5px;
//         cursor: pointer;
//         text-align: center;

//         @include media(get-bp(desktop)) {
//             padding: 20px 35px;
//             letter-spacing: 3px;
//         }
        
//         small {
//             display: flex;
//             text-align: center;
//             cursor: pointer;
//             i {
//                 margin-right: 10px;
//             }
//         }
//     }
    
//     .custom-controls li.active span{
//         opacity: 1;
//         transform: scale(1);
//     }
    
//     .arrow-nav {
//         display: flex;
//         justify-content: space-around;
//         position: absolute;
//         top: 40%;
//         left: 0;
//         &.next-nav {
//             left: unset;
//             right:0;
//         }
       
//     }

    
    
//     .arrow-nav button {
//         cursor: pointer;
//         border: none;

//         color: white;
//         background: #000 url('../img/arrow.svg') center center no-repeat;
//         outline: none;
//         z-index: 1;

//         -webkit-clip-path: polygon(100% 0, 31% 30%, 31% 66%, 100% 100%);
//         clip-path: polygon(100% 0, 31% 30%, 31% 66%, 100% 100%);
//         i{
//             font-weight: 900;
//             font-size: 24px;
//             padding-left: 1pc;
//         }
//         width: 60px;
//         height: 200px;
//         @include media(get-bp(desktop)) {
//             width: 120px;
//             height: 300px;

//             i{
//                 font-size: 50px;
//                 padding-left: 3pc;
//             }
//         }
//     }
    
//     .arrow-nav button:hover {
//         background: #333 url('../img/arrow.svg') center center no-repeat;
//         transition: ease-in-out .3s;
//     }
    
//     .arrow-nav button.prev {
//         transform: rotate(-180deg);
       
//         button {
//             -webkit-clip-path: polygon(0 0, 66% 29%, 66% 74%, 0% 100%);
//             clip-path: polygon(0 0, 66% 29%, 66% 74%, 0% 100%);
            
//         } 
//     }
    
//     .wrap-controls {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//     }
// }

// .slider-passadores  {
//     width: 70%;
//     position: absolute;
//     top: 50%;
//     z-index: 3;
//     left: 50%;
//     transform: translateX(-50%);
// }



#surroundings {
    position: relative;

    H2 {
        position: relative;
        
        left: 50%;
        transform: translateX(-50%);
        z-index: 9;
        background: #fff;
        padding: 20px;
        width: 100%;
        text-align: center;

        @include media(get-bp(desktop)) {
            position: absolute;
            width: max-content;
            top: 50PX;
        }
    }


    .prev-slideA, .next-slideA {
        cursor: pointer;
        border: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        color: white;
        right: 0;
        outline: none;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.3s;
        &.swiper-button-disabled {
            opacity: 0;
        }
        @include media(get-bp(desktop),max) {
            max-width: max-content;
            right: 30px;
            background: transparent !important;
        }

        &:hover {
            background: #333;
        }
        
        @include media(get-bp(desktop)) {
            
            -webkit-clip-path: polygon(100% 0, 31% 30%, 31% 66%, 100% 100%);
            clip-path: polygon(100% 0, 31% 30%, 31% 66%, 100% 100%);
            width: 60px;
            background: #000 url('../img/arrow.svg') center center no-repeat;
            height: 200px;
        }
        opacity: 1;
        i{
            font-weight: 900;
            font-size: 24px;
            padding-left: 1pc;
            
        }
        
        
        @include media(get-bp(desktop)) {
            width: 120px;
            height: 300px;

            i{
                font-size: 50px;
                padding-left: 3pc;
            }
        }
    }


    .prev-slideA  {
        left: 0;
        top: 50%;
        transform: rotate(-180deg) translateY(50%);
        @include media(get-bp(desktop), max) {
            left: 30px;
        }
    }

    .sliderSurroundings {
        position: relative;
        width: 100%;
        z-index: 2;
    }
    .sliderSurroundings .swiper-slide{
        display: flex;
        justify-content: center;
        align-items: center;
        background: #000;

        img {
            opacity: 0.4;
            width: 100%;
            height: 500px;
            object-fit: cover;
        }

        p {
            position: absolute;
            opacity: .8;
            transform: scale(.8);
            overflow: hidden;
            margin: 2px;
            // box-shadow: 0 2px 2px rgba(0,0,0,.5);
            transition: .3s;
            cursor: pointer;
            text-transform: uppercase;
            color: #fff;
            font-weight: 800;
            font-size: 25px;
            border: 4px solid #fff;
            padding: 20px;
            letter-spacing: 1.5px;
            cursor: pointer;
            text-align: center;
            width: 70%;
    
            @include media(get-bp(desktop)) {
                padding: 20px 35px;
                letter-spacing: 3px;
                max-width: max-content;
            }
            
            small {
                display: flex;
                text-align: center;
                cursor: pointer;
                i {
                    margin-right: 10px;
                }
            }
        }
    }
}