.floating-chat{
    position: fixed;
    bottom: 10%;
    right: 0;
    background-color: #000;
    color: #fff;
    width: 250px;
    z-index: 10;
    transform: translateX(75%);
    transition: 0.4s;
    padding: 20px;
    opacity: 0;    
    display: none;
    opacity: 1;
    @media (min-width: 1200px){
        bottom: 30%;
        width: 250px;
        transform: translateX(180px);
        display: none;
    }
    &:hover{
        transform: translateX(-27%);
        @media (min-width: 1200px){
            transform: translateX(-12%);
        }
        .hover-categories{
            height: auto;
            width: 100%;
            display: block;
        }
        .contact-category{
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 20px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    &.show{
        opacity: 1;
    }
    .close{
        background-color: #000;
        color: #fff;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -45px;
        right: 0;
    }
    .hover-categories{
        height: 0px;
        width: 0px;
        transition: 0.4s;
        display: none;
        margin-bottom: 20px;
    }
    .contact-category{
        display: flex;
        text-transform: uppercase;
        cursor: pointer;
        &:hover{
            text-decoration: underline;
        }
        .icon{
            width: 30px;
            margin-right: 30px;
        }
    }
}

.wpp-contact {
    position: fixed;
    bottom: 30px;
    right: 10px;
    font-size: 30px;
    color: #25d366;
    border-radius: 100%;
    z-index: 9;
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    

    @include media(get-bp(desktop)) {
       background: #000;
       width: 165.45px;
       text-align: center;
       border-radius: 30px;
       right: 30px;
       bottom: 190px;
       padding: 15px 0px;
       display: block;
       text-align: center;
    }

    p {
        display: none;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;

        i {
            font-size: 22px;
            margin-right: 10px;
            color: #008c00;
            font-weight: normal;
        }

        @include media(get-bp(desktop)) {
            display: flex;
            align-items: center;
            margin-left: 20px;
        }
    }

    img{
        display: block;
        @include media(get-bp(desktop)) {
            display: none;
        }
    }
    
}