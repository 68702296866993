.entertainement{
    background-color: #f7f7f7;
    padding-bottom: 50px;
    @media (min-width: 1200px){
        padding-bottom: 150px;
        padding-top: 80px;
    }
    .title{
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 50px;
        .small-text{
            font-size: 16px;
        }
        .big-text{
            font-weight: bold;
            font-size: 32px;
        }
    }

    .tags-listing{
        display: flex;
        flex-wrap: wrap;
        
        .tag-item{
            width: 100%;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            @media (min-width: 1200px){
                width: 33%;
                margin-bottom: 30px;
            }
            .photo{
                background-color: #c6b9a6;
                padding: 10px;
                border-radius: 8px;
                width: 80px;
                height: 80px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 10px;
            }
            .text{
                font-weight: bold;
                text-transform: uppercase;
            }
        }
    }
}