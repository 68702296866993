.container {
    @include container($width: 120rem, $padding: 0 1rem);

    @include media(get-bp(desktop)) {
        padding: 0 1rem;
    }
}

.container--content {
    @include container($width: 100rem, $padding: 0);
}
