.ebook-section{
    padding-bottom: 100px;
    background-color: #f7f7f7;
    @media (min-width: 1200px){
        padding-top: 80px;
    }
    .title{
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 50px;
        .small-text{
            font-size: 12px;
        }
        .big-text{
            font-weight: bold;
            font-size: 22px;
            line-height: 1.2;
        }
    }

    .form-container{
        width: 100%;
        @media (min-width: 1200px){
            width: 400px;
            margin: auto;
        }
        .input-item{
            width: 100%;
            margin-bottom: 20px;
            label{
                text-transform: uppercase;
                font-size: 10px;
            }
            input{
                border: 0;
                background-color: transparent;
                padding: 10px;
                width: 100%;
                outline: none;
                text-transform: uppercase;
                font-size: 14px;
                border: 1px solid #898989;
                border-radius: 8px;
                height: 45px;
            }
        }
    }

    .send{
        margin-top: 50px;
        background-color: #015b2c;
        color: #fff;
        padding: 20px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        text-transform: uppercase;
        font-size: 14px;
        transition: 0.4s;
        display: block;
        border: none;
        width: 100%;
        text-align: center;
        &:hover{
            opacity: 0.8;
        }
    }
}

.modal-video{
    .video-container{
        width: 100%;
        height: 400px;
        iframe{
            width: 100%;
            height: 100%;
        }
    }
}