.menu-pages-forms {
    @include media(get-bp(desktop)) {
        background-color: #000;
        color: #fff;
    }
    ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        list-style: none;
        padding: 0;
        @include media(get-bp(desktop)) {
            flex-direction: row;
        }
        li {
            width: 100%;
        }
        li a{
            width: 100%;
            padding: 10px;
            display: block;
            width: 100%;
            text-align: center;
            border-bottom: 1px solid #00000014;
            font-weight: 600;
            transition: 0.3s;
            cursor: pointer;
            &:hover {
                background: #e3e3e3;
                color: #000;
            }

            @include media(get-bp(desktop)) {
                border: none;
            }
        }

        li:nth-last-child(1) a {
            border-color: transparent;
        }

        li.is-active {
            background: #015b2c;
                color: #fff;
        }

        
    }
}