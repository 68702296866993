#evolution {
    background: #d0d0d05e;
    color: #fff;
    padding: 50px 0;
    margin-bottom: 50px;

    h2 {
        text-align: center;
        font-size: 32px;
        color: get-color(secondary);
        margin-bottom: 50px;
    }

    .flex-parts {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @include media(get-bp(desktop)) {
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;

            > a {
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-between;
                align-items: center;
                width: 52%;
            }
        }

       
    }
    .percent {
        border-radius: 100%;
        height: 200px;
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        background: get-color(secondary);
        z-index: 3;
        flex-direction: column-reverse;
        border: 10px solid #fff;
        margin-bottom: 60px;
        
        &::before {
            border-radius: 100%;
            background: #015a2c;
            position: absolute;
            content: "";
            height: 100%;
            width: 100%;
            z-index: -2;
            opacity: 0;
            animation: cool-border 3s infinite;
        }

        &::after {
            border-radius: 100%;
            background: #015a2c;
            position: absolute;
            content: "";
            height: 100%;
            width: 100%;
            z-index: -2;
            opacity: 0;
            animation: cool-border2 3s infinite;
            animation-delay: 1.5s;
        }

        p {
            text-align: center;
            b {
                font-size: 30px;
                display: block;
            }
        }

        i {
            font-size: 40px;
            margin-bottom: 5px;
        }
    }

    .graphics {
        margin-bottom: 40px;

        ul {
            padding: 0;
            list-style: none;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        p {
            text-transform: uppercase;
            text-align: center;
            font-weight: bold;
            color: #000;
            font-size: 15px;

            @include media(get-bp(desktop)) {
                font-size: 13px;
            }
        }

        figure {
            width: 200px;
            background: #fff;
            height: 11px;
            margin: 0;
            margin-left: 10px;
            border-radius: 30px;
            position: relative;
            overflow: hidden;
            // border: 2px solid #000;
            span {
                background: #015a2c;
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                border-radius: 30px;
            }
        }

        li {
            margin-bottom: 20px;
            @include media(get-bp(desktop)) {
                margin-bottom: 10px;
            }
        }

        li > div {
            display: flex;
            align-items: center;
            color: #2a4036;
            font-weight: 500;
            @include media(get-bp(desktop)) {
                font-size: 13px;
            }
        }
    }

    .slider-zoom {
        width: 100%;
        @include media(get-bp(desktop)) {
         width: 40%;   
        }
        img {
            display: block;
            width: 100%;
        }
        .slider-zoom-evolution {
            width: 100%;
        }

        .swiper-slide i{
            position: absolute;
            top: 20px;
            left: 20px;
        }
        .prevSlide {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            height: 83px;
            width: 40px;
            background: #000;
            z-index: 3;
            -webkit-clip-path: polygon(0 0, 0% 100%, 100% 52%);
            clip-path: polygon(0 0, 0% 100%, 100% 52%);
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-left: 6px;
        }

        .nextSlide {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            height: 83px;
            width: 40px;
            background: #000;
            z-index: 3;
            -webkit-clip-path: polygon(100% 0, 100% 100%, 0 48%);
            clip-path: polygon(100% 0, 100% 100%, 0 48%);
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 6px;
        }
    }

}

@keyframes cool-border {
    0%{
        opacity: 0; 
        height: 100%;
        width: 100%;
    }
    50%{
        opacity: 0.15; 
        height: 125%;
        width: 125%;
    }
}

.video-evolution {
    width: 80%;
    height: 250px;
    @include media(get-bp(desktop)) {
        width: 50%;
        height: 333px;
    }

    iframe, video {
        width: 100%;
        height: 100%;
    }
}


@keyframes cool-border2 {
    0%{
        opacity: 0; 
        height: 100%;
        width: 100%;
    }
    50%{
        opacity: 0.4; 
        height: 130%;
        width: 130%;
    }
}