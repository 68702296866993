.is-title{
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 50px;
    margin-top: 50px;
    .small-text{
        font-size: 16px;
    }
    .big-text{
        font-weight: bold;
        font-size: 32px;
    }
}
.photo-gallery{
    position: relative;
    width: 100%;
    height: 400px;

    @media (min-width: 1200px){
        height: 600px;
    }
    .title{
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        padding: 20px;
        z-index: 3;
        width: 100%;
        color: #fff;
        font-weight: bold;
        font-size: 22px;
        @media (min-width: 1200px){
            font-size: 32px;
        }
    }
    .main-photo-gallery{
        width: 100%;
        height: 100%;

        .desc-image {
            position: absolute;
            z-index: 9;
            bottom: 0;
            color: #fff;
            background: #00000075;
            width: 100%;
            padding: 10px;
            font-size: 14px;
            text-align: center;
            @media (min-width: 1200px){
                bottom: 120px;
            }
        }

        .photo-gallery-item{
            width: 100%;
            height: 100%;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        
        .swiper-pagination{
            bottom: 40px;
            max-width: 80%;
            left: 50%;
            transform: translateX(-50%);
            @media (min-width: 1200px){
                bottom: 65px;
            }
        }
        .swiper-pagination-bullet{
            width: 15px;
            height: 5px;
            border-radius: 1px;
            position: relative;
            transition: 0.4s;
            background-color: #fff;
            margin-right: 5px;
            opacity: 1;
            &.swiper-pagination-bullet-active{
                background: #fff;
                height: 25px;
            }
        }

        .see-call{
            width: 100%;
            display: flex;
            justify-content: center;
            position: absolute;
            bottom: 60px;
            @media (min-width: 1200px){
                bottom: 15px;
            }
            left: 0;
            z-index: 3;
            a{
                border: 3px solid #fff;
                padding: 10px 20px;
                font-size: 14px;
                color: #fff;
                font-weight: bold;
                transition: 0.4s;
                &:hover{
                    background-color: #fff;
                    color: #898989;
                }
            }
        }
    }

    .swiper-button-next, .swiper-button-prev{
        display: block;
        @media (min-width: 1200px){
            display: none;
        }
    }

    .slide-control{
        position: absolute;
        top: 55%;
        z-index: 30;
        display: none;
        @media (min-width: 1200px){
            top: 35%;
            display: block;
        }
        &.slide-prev{
             left: 20px;
             cursor: pointer;
             .content{
                 color: #fff;
                 font-size: 24px;
             }
             &.hide{
                 transform: translateX(-100%);
             }
             @media (min-width: 1200px){
                 left: 0px;
                 border-top: 80px solid transparent;
                 border-bottom: 80px solid transparent;
                 border-left: 80px solid #000;
                 transition: 0.6s;
                 &:hover{
                     opacity: 0.6;
                 }
                 .content{
                     transition: 0.4s;
                     background-color: #000;
                     font-size: 72px;
                     display: flex;
                     align-items: center;
                     justify-content: center;
                     color: #fff;
                     margin-left: -100px;
                     
                 }
             }
        }
        &.slide-next{
             right: 20px;
             cursor: pointer;
             .content{
                 color: #fff;
                 font-size: 24px;
             }
             &.hide{
                 transform: translateX(100%);
             }
             @media (min-width: 1200px){
                 right: 00px;
                 border-top: 80px solid transparent;
                 border-bottom: 80px solid transparent;
                 border-right: 80px solid #000;
                 transition: 0.6s;
                 &:hover{
                     opacity: 0.6;
                 }
                 .content{
                     transition: 0.4s;
                     background-color: #000;
                     font-size: 72px;
                     display: flex;
                     align-items: center;
                     justify-content: center;
                     color: #fff;
                     margin-right: -100px;
                     
                 }
             }
        }
    }
}

#all-thumbs {
    position: absolute;
    background: #000000de;
    padding-top: 100px;
    z-index: 9999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    visibility: hidden;
    opacity: 0;
    transition: 0.5s;

    &.is-active {
        visibility: visible;
        opacity: 1;
    }

    ul {
        display: grid;
        list-style: none;
        padding: 0;
        grid-template-columns: auto auto auto;
        grid-column-gap: 10px;
        
        @include media(get-bp(tablet-landscape)){
            grid-template-columns: auto auto auto auto auto;
            grid-row-gap: 20px;
            grid-column-gap: 20px;
        }
    }

    .frame img{
       width: 100%;
       object-fit: cover;
       min-height: 100px;
       @include media(get-bp(tablet-landscape)){
           width: 150px;
           height: 150px;
       }
       
    }
}

.modal-galeria-photo {
    img{
        display: block;
        max-width: 100%;
        margin: auto;
    }

    .modal__box {
        max-width: unset;
        width: max-content;
    }
}

figure.zoom {
    width: 100% !important;
    margin: 0;
}