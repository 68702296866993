.our-buildings{
    position: relative;
    @media (min-width: 1200px){
        margin-bottom: 100px;
    }
    
    .container{
        margin-top: 50px;
    }
    .title-row{
        line-height: 1.2;
        padding-top: 10px;
        margin-bottom: 40px;
        @media (min-width: 1200px){
           
        }
        .small-text{
            text-transform: uppercase;
            font-size: 14px;
            @media (min-width: 1200px){
                font-size: 22px;
            }
        }
        .big-text{
            font-size: 20px;
            text-transform: uppercase;
            font-weight: bold;
            @media (min-width: 1200px){
                font-size: 42px;
            }
        }
        &:after{
            content: '';
            width: 46px;
            height: 1px;
            background-color: #000;
            position: absolute;
            top: 0;
            left: 20px;
            @media (min-width: 1200px){
                top: 22px;
                left: -40px;
            }
        }
    }


    .buildings-listing{
        .mobile-content{
            display: block;
            @media (min-width: 1200px){
                display: none;
            }
            .building-item{
                padding: 0;
                padding-bottom: 20px;
                @media (min-width: 1200px){
                    padding-bottom: 70px;
                }
                height: 350px;
                .text-content{
                    padding-left: 20px;
                }
            }

            .swiper-pagination-bullet{
                width: 10px;
                height: 10px;
                border-radius: 1px;
                position: relative;
                z-index: 99;
                transition: 0.4s;
                background-color: #aaa;
                border-radius: 50%;
                &.swiper-pagination-bullet-active{
                    background: #cccc;
                }
            }
        }
        .desktop-content{
            display: none;
            flex-wrap: wrap;
            @media (min-width: 1200px){
                display: flex;
            }
        }
        .building-item{
            overflow: hidden;
            width: 100%;
            position: relative;
            padding: 10px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding-bottom: 30px;
            margin-bottom: 40px;
            @media (min-width: 1200px){
                height: 500px;
                margin-bottom: 10px;
                width: 32.3%;
                margin-right: 1%;
            }
            &:hover{
                .photo-position{
                    .front-photo{
                        opacity: 0;
                    }
                }
            }

            .box-detail{
                position: absolute;
                bottom: -20px;
                left: 90px;
                z-index: 4;
                filter: brightness(3);
                opacity: 0.05;
            }
            .photo-position{
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                transition: 0.4s;
                z-index: 2;
                .swiper-container{
                    width: 100%;
                    height: 100%;
                    
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    opacity: 1;              
                }
                
            }

            .photo-pagination{
                position: absolute;
                top: 15px;
                left: 15px;
                width: 100px;
                display: flex;
                z-index: 5;
            }
            .swiper-pagination-bullet{
                width: 10px;
                height: 5px;
                border-radius: 1px;
                position: relative;
                transition: 0.4s;
                background-color: #fff;
                margin-right: 5px;
                opacity: 1;
                &.swiper-pagination-bullet-active{
                    background: #fff;
                    height: 40px;
                }
            }

            .overlay{
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: linear-gradient( rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 100%);
                transform: rotate(180deg);
                z-index: 3;
            }
            .text-content{
                position: relative;
                z-index: 4;
                color: #fff;
               
                .title{
                    font-weight: bold;
                    color: #fff;
                    text-transform: uppercase;
                    font-size: 28px;
                    margin-bottom: 15px;
                    letter-spacing: 3px;
                    line-height: 1.2;

                }
                .desc-item{
                    display: flex;
                    margin-bottom: 5px;
                    .icon{
                        margin-right: 10px;
                    }
                }

                .status{
                    font-weight: 900;
                    text-transform: uppercase;
                    margin-top: 5px;
                    letter-spacing: 3px;
                    font-size: 14px;
                    margin-left: 35px;
                }
                
            }

            .online{
                background-color: #4b4e4d;
                padding: 10px;
                color: #fff;
                position: absolute;
                top: 10px;
                right: 10px;
                z-index: 3;
                border-radius: 4px;
                font-size: 12px;
                text-transform: uppercase;
                font-weight: bold;
                transition: 0.4s;
                text-align: center;
                i{
                    font-size: 18px;
                }
                &:hover{
                    opacity: 0.8;
                }
            }

            .image-bars{
                width: 60px;
                position: absolute;
                top: 35px;
                left: 20px;
                z-index: 3;
                display: flex;
                .bar{
                    margin-right: 5px;
                    width: 10px;
                    height: 5px;
                    background-color: #fff;
                    transition: 0.4s;
                    &.active{
                        height: 30px;
                    }
                }
            }
        }
    }
}