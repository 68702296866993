.rewards{
    margin-top: 100px;
    .container{
        .section-title{
            text-align: center;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 10px;
            font-size: 30px;
        }
        .section-subtitle{
            text-align: center;
            font-size: 18px;
            text-transform: uppercase;
            margin-bottom: 100px;
            display: none;
            @media (min-width: 1200px){
                display: block;
                
            }
        }

        .medal-row{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 40px;
            @media (min-width: 1200px){
                justify-content: flex-start;
                padding-left: 110px;
            }
            .icon{
                margin-right: 10px;
            }
            .text{
                font-weight: bold;
            }
        }

        .rewards-listing{
            .reward-item{
                text-align: center;
                margin-bottom: 30px;
                @media (min-width: 1200px){
                    display: flex;
                    text-align: left;
                    align-items: flex-start;
                }
                .year-title{
                    font-weight: bold;
                    font-size: 24px;
                    position: relative;
                    display: inline-flex;
                    align-items: flex-start;
                    @media (min-width: 1200px){
                        font-size: 32px;
                        margin-right: 30px;
                    }
                    .number{
                        position: relative;
                        z-index: 2;
                    }
                    .line{
                        position: absolute;
                        bottom: 3px;
                        left: -1px;
                        width: 65px;
                        height: 10px;
                        background-color: #85ad11;
                        z-index: 1;
                        @media (min-width: 1200px){
                            width: 83px;
                        }
                    }
                }

                .year-content{
                    position: relative;
                    font-size: 14px;
                    @media (min-width: 1200px){
                        padding-left: 50px;
                        font-size: 16px;
                        &::after{
                            content: '';
                            position: absolute;
                            top: 20px;
                            left: 0;
                            width: 30px;
                            height: 1px;
                            background-color: #000;
                        }
                    }
                }
            }
        }
    }
}